import React from 'react';
import { FC } from 'react';

import { ReactComponent as Arrow } from '../../Components/Icons/newArrow.svg';

import './MainButton.scss';
import { Link } from 'react-router-dom';

type Props = {
	text: string;
	filled?: boolean;
	white?: boolean;
	showArrow?: boolean;
	route?: string;
};

export const MainButton: FC<Props> = ({ filled, text, white, route, showArrow = true }) => {
	return route && route.startsWith('http') ? (
		<a
			href={route}
			target="_blank"
			rel="noopener noreferrer"
			className={filled ? 'filledMainButton' : white ? 'whiteButton' : 'mainButton'}
		>
			{text}
			{showArrow && <Arrow />}
		</a>
	) : (
		<Link to={route ?? ''} className={filled ? 'filledMainButton' : white ? 'whiteButton' : 'mainButton'}>
			{text}
			{showArrow && <Arrow />}
		</Link>
	);
};
